/*!
 * cleave.js - 1.5.3
 * https://github.com/nosir/cleave.js
 * Apache License Version 2.0
 *
 * Copyright (C) 2012-2019 Max Huang https://github.com/nosir/
 */
!(function(e, t) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = t())
    : "function" == typeof define && define.amd
    ? define([], t)
    : "object" == typeof exports
    ? (exports.Cleave = t())
    : (e.Cleave = t());
})(this, function() {
  return (function(e) {
    function t(i) {
      if (r[i]) return r[i].exports;
      var n = (r[i] = { exports: {}, id: i, loaded: !1 });
      return e[i].call(n.exports, n, n.exports, t), (n.loaded = !0), n.exports;
    }
    var r = {};
    return (t.m = e), (t.c = r), (t.p = ""), t(0);
  })([
    function(e, t, r) {
      (function(t) {
        "use strict";
        var i = function(e, t) {
          var r = this,
            n = !1;
          if (
            ("string" == typeof e
              ? ((r.element = document.querySelector(e)),
                (n = document.querySelectorAll(e).length > 1))
              : "undefined" != typeof e.length && e.length > 0
              ? ((r.element = e[0]), (n = e.length > 1))
              : (r.element = e),
            !r.element)
          )
            throw new Error("[cleave.js] Please check the element");
          if (n)
            try {
              console.warn(
                "[cleave.js] Multiple input fields matched, cleave.js will only take the first one."
              );
            } catch (a) {}
          (t.initValue = r.element.value),
            (r.properties = i.DefaultProperties.assign({}, t)),
            r.init();
        };
        (i.prototype = {
          init: function() {
            var e = this,
              t = e.properties;
            return t.numeral ||
              t.phone ||
              t.creditCard ||
              t.time ||
              t.date ||
              0 !== t.blocksLength ||
              t.prefix
              ? ((t.maxLength = i.Util.getMaxLength(t.blocks)),
                (e.isAndroid = i.Util.isAndroid()),
                (e.lastInputValue = ""),
                (e.onChangeListener = e.onChange.bind(e)),
                (e.onKeyDownListener = e.onKeyDown.bind(e)),
                (e.onFocusListener = e.onFocus.bind(e)),
                (e.onCutListener = e.onCut.bind(e)),
                (e.onCopyListener = e.onCopy.bind(e)),
                e.element.addEventListener("input", e.onChangeListener),
                e.element.addEventListener("keydown", e.onKeyDownListener),
                e.element.addEventListener("focus", e.onFocusListener),
                e.element.addEventListener("cut", e.onCutListener),
                e.element.addEventListener("copy", e.onCopyListener),
                e.initPhoneFormatter(),
                e.initDateFormatter(),
                e.initTimeFormatter(),
                e.initNumeralFormatter(),
                void (
                  (t.initValue || (t.prefix && !t.noImmediatePrefix)) &&
                  e.onInput(t.initValue)
                ))
              : void e.onInput(t.initValue);
          },
          initNumeralFormatter: function() {
            var e = this,
              t = e.properties;
            t.numeral &&
              (t.numeralFormatter = new i.NumeralFormatter(
                t.numeralDecimalMark,
                t.numeralIntegerScale,
                t.numeralDecimalScale,
                t.numeralThousandsGroupStyle,
                t.numeralPositiveOnly,
                t.stripLeadingZeroes,
                t.prefix,
                t.signBeforePrefix,
                t.delimiter
              ));
          },
          initTimeFormatter: function() {
            var e = this,
              t = e.properties;
            t.time &&
              ((t.timeFormatter = new i.TimeFormatter(
                t.timePattern,
                t.timeFormat
              )),
              (t.blocks = t.timeFormatter.getBlocks()),
              (t.blocksLength = t.blocks.length),
              (t.maxLength = i.Util.getMaxLength(t.blocks)));
          },
          initDateFormatter: function() {
            var e = this,
              t = e.properties;
            t.date &&
              ((t.dateFormatter = new i.DateFormatter(
                t.datePattern,
                t.dateMin,
                t.dateMax
              )),
              (t.blocks = t.dateFormatter.getBlocks()),
              (t.blocksLength = t.blocks.length),
              (t.maxLength = i.Util.getMaxLength(t.blocks)));
          },
          initPhoneFormatter: function() {
            var e = this,
              t = e.properties;
            if (t.phone)
              try {
                t.phoneFormatter = new i.PhoneFormatter(
                  new t.root.Cleave.AsYouTypeFormatter(t.phoneRegionCode),
                  t.delimiter
                );
              } catch (r) {
                throw new Error(
                  "[cleave.js] Please include phone-type-formatter.{country}.js lib"
                );
              }
          },
          onKeyDown: function(e) {
            var t = this,
              r = t.properties,
              n = e.which || e.keyCode,
              a = i.Util,
              o = t.element.value;
            (t.hasBackspaceSupport = t.hasBackspaceSupport || 8 === n),
              !t.hasBackspaceSupport &&
                a.isAndroidBackspaceKeydown(t.lastInputValue, o) &&
                (n = 8),
              (t.lastInputValue = o);
            var l = a.getPostDelimiter(o, r.delimiter, r.delimiters);
            8 === n && l
              ? (r.postDelimiterBackspace = l)
              : (r.postDelimiterBackspace = !1);
          },
          onChange: function() {
            this.onInput(this.element.value);
          },
          onFocus: function() {
            var e = this,
              t = e.properties;
            i.Util.fixPrefixCursor(
              e.element,
              t.prefix,
              t.delimiter,
              t.delimiters
            );
          },
          onCut: function(e) {
            i.Util.checkFullSelection(this.element.value) &&
              (this.copyClipboardData(e), this.onInput(""));
          },
          onCopy: function(e) {
            i.Util.checkFullSelection(this.element.value) &&
              this.copyClipboardData(e);
          },
          copyClipboardData: function(e) {
            var t = this,
              r = t.properties,
              n = i.Util,
              a = t.element.value,
              o = "";
            o = r.copyDelimiter
              ? a
              : n.stripDelimiters(a, r.delimiter, r.delimiters);
            try {
              e.clipboardData
                ? e.clipboardData.setData("Text", o)
                : window.clipboardData.setData("Text", o),
                e.preventDefault();
            } catch (l) {}
          },
          onInput: function(e) {
            var t = this,
              r = t.properties,
              n = i.Util,
              a = n.getPostDelimiter(e, r.delimiter, r.delimiters);
            return (
              r.numeral ||
                !r.postDelimiterBackspace ||
                a ||
                (e = n.headStr(e, e.length - r.postDelimiterBackspace.length)),
              r.phone
                ? (!r.prefix || (r.noImmediatePrefix && !e.length)
                    ? (r.result = r.phoneFormatter.format(e))
                    : (r.result =
                        r.prefix +
                        r.phoneFormatter.format(e).slice(r.prefix.length)),
                  void t.updateValueState())
                : r.numeral
                ? (r.prefix && r.noImmediatePrefix && 0 === e.length
                    ? (r.result = "")
                    : (r.result = r.numeralFormatter.format(e)),
                  void t.updateValueState())
                : (r.date && (e = r.dateFormatter.getValidatedDate(e)),
                  r.time && (e = r.timeFormatter.getValidatedTime(e)),
                  (e = n.stripDelimiters(e, r.delimiter, r.delimiters)),
                  (e = n.getPrefixStrippedValue(
                    e,
                    r.prefix,
                    r.prefixLength,
                    r.result,
                    r.delimiter,
                    r.delimiters,
                    r.noImmediatePrefix
                  )),
                  (e = r.numericOnly ? n.strip(e, /[^\d]/g) : e),
                  (e = r.uppercase ? e.toUpperCase() : e),
                  (e = r.lowercase ? e.toLowerCase() : e),
                  !r.prefix ||
                  (r.noImmediatePrefix && !e.length) ||
                  ((e = r.prefix + e), 0 !== r.blocksLength)
                    ? (r.creditCard && t.updateCreditCardPropsByValue(e),
                      (e = n.headStr(e, r.maxLength)),
                      (r.result = n.getFormattedValue(
                        e,
                        r.blocks,
                        r.blocksLength,
                        r.delimiter,
                        r.delimiters,
                        r.delimiterLazyShow
                      )),
                      void t.updateValueState())
                    : ((r.result = e), void t.updateValueState()))
            );
          },
          updateCreditCardPropsByValue: function(e) {
            var t,
              r = this,
              n = r.properties,
              a = i.Util;
            a.headStr(n.result, 4) !== a.headStr(e, 4) &&
              ((t = i.CreditCardDetector.getInfo(e, n.creditCardStrictMode)),
              (n.blocks = t.blocks),
              (n.blocksLength = n.blocks.length),
              (n.maxLength = a.getMaxLength(n.blocks)),
              n.creditCardType !== t.type &&
                ((n.creditCardType = t.type),
                n.onCreditCardTypeChanged.call(r, n.creditCardType)));
          },
          updateValueState: function() {
            var e = this,
              t = i.Util,
              r = e.properties;
            if (e.element) {
              var n = e.element.selectionEnd,
                a = e.element.value,
                o = r.result;
              if (
                ((n = t.getNextCursorPosition(
                  n,
                  a,
                  o,
                  r.delimiter,
                  r.delimiters
                )),
                e.isAndroid)
              )
                return void window.setTimeout(function() {
                  (e.element.value = o),
                    t.setSelection(e.element, n, r.document, !1),
                    e.callOnValueChanged();
                }, 1);
              (e.element.value = o),
                t.setSelection(e.element, n, r.document, !1),
                e.callOnValueChanged();
            }
          },
          callOnValueChanged: function() {
            var e = this,
              t = e.properties;
            t.onValueChanged.call(e, {
              target: { value: t.result, rawValue: e.getRawValue() }
            });
          },
          setPhoneRegionCode: function(e) {
            var t = this,
              r = t.properties;
            (r.phoneRegionCode = e), t.initPhoneFormatter(), t.onChange();
          },
          setRawValue: function(e) {
            var t = this,
              r = t.properties;
            (e = void 0 !== e && null !== e ? e.toString() : ""),
              r.numeral && (e = e.replace(".", r.numeralDecimalMark)),
              (r.postDelimiterBackspace = !1),
              (t.element.value = e),
              t.onInput(e);
          },
          getRawValue: function() {
            var e = this,
              t = e.properties,
              r = i.Util,
              n = e.element.value;
            return (
              t.rawValueTrimPrefix &&
                (n = r.getPrefixStrippedValue(
                  n,
                  t.prefix,
                  t.prefixLength,
                  t.result,
                  t.delimiter,
                  t.delimiters
                )),
              (n = t.numeral
                ? t.numeralFormatter.getRawValue(n)
                : r.stripDelimiters(n, t.delimiter, t.delimiters))
            );
          },
          getISOFormatDate: function() {
            var e = this,
              t = e.properties;
            return t.date ? t.dateFormatter.getISOFormatDate() : "";
          },
          getISOFormatTime: function() {
            var e = this,
              t = e.properties;
            return t.time ? t.timeFormatter.getISOFormatTime() : "";
          },
          getFormattedValue: function() {
            return this.element.value;
          },
          destroy: function() {
            var e = this;
            e.element.removeEventListener("input", e.onChangeListener),
              e.element.removeEventListener("keydown", e.onKeyDownListener),
              e.element.removeEventListener("focus", e.onFocusListener),
              e.element.removeEventListener("cut", e.onCutListener),
              e.element.removeEventListener("copy", e.onCopyListener);
          },
          toString: function() {
            return "[Cleave Object]";
          }
        }),
          (i.NumeralFormatter = r(1)),
          (i.DateFormatter = r(2)),
          (i.TimeFormatter = r(3)),
          (i.PhoneFormatter = r(4)),
          (i.CreditCardDetector = r(5)),
          (i.Util = r(6)),
          (i.DefaultProperties = r(7)),
          (("object" == typeof t && t ? t : window).Cleave = i),
          (e.exports = i);
      }.call(
        t,
        (function() {
          return this;
        })()
      ));
    },
    function(e, t) {
      "use strict";
      var r = function(e, t, i, n, a, o, l, s, c) {
        var u = this;
        (u.numeralDecimalMark = e || "."),
          (u.numeralIntegerScale = t > 0 ? t : 0),
          (u.numeralDecimalScale = i >= 0 ? i : 2),
          (u.numeralThousandsGroupStyle = n || r.groupStyle.thousand),
          (u.numeralPositiveOnly = !!a),
          (u.stripLeadingZeroes = o !== !1),
          (u.prefix = l || "" === l ? l : ""),
          (u.signBeforePrefix = !!s),
          (u.delimiter = c || "" === c ? c : ","),
          (u.delimiterRE = c ? new RegExp("\\" + c, "g") : "");
      };
      (r.groupStyle = {
        thousand: "thousand",
        lakh: "lakh",
        wan: "wan",
        none: "none"
      }),
        (r.prototype = {
          getRawValue: function(e) {
            return e
              .replace(this.delimiterRE, "")
              .replace(this.numeralDecimalMark, ".");
          },
          format: function(e) {
            var t,
              i,
              n,
              a,
              o = this,
              l = "";
            switch (
              ((e = e
                .replace(/[A-Za-z]/g, "")
                .replace(o.numeralDecimalMark, "M")
                .replace(/[^\dM-]/g, "")
                .replace(/^\-/, "N")
                .replace(/\-/g, "")
                .replace("N", o.numeralPositiveOnly ? "" : "-")
                .replace("M", o.numeralDecimalMark)),
              o.stripLeadingZeroes && (e = e.replace(/^(-)?0+(?=\d)/, "$1")),
              (i = "-" === e.slice(0, 1) ? "-" : ""),
              (n =
                "undefined" != typeof o.prefix
                  ? o.signBeforePrefix
                    ? i + o.prefix
                    : o.prefix + i
                  : i),
              (a = e),
              e.indexOf(o.numeralDecimalMark) >= 0 &&
                ((t = e.split(o.numeralDecimalMark)),
                (a = t[0]),
                (l =
                  o.numeralDecimalMark + t[1].slice(0, o.numeralDecimalScale))),
              "-" === i && (a = a.slice(1)),
              o.numeralIntegerScale > 0 &&
                (a = a.slice(0, o.numeralIntegerScale)),
              o.numeralThousandsGroupStyle)
            ) {
              case r.groupStyle.lakh:
                a = a.replace(/(\d)(?=(\d\d)+\d$)/g, "$1" + o.delimiter);
                break;
              case r.groupStyle.wan:
                a = a.replace(/(\d)(?=(\d{4})+$)/g, "$1" + o.delimiter);
                break;
              case r.groupStyle.thousand:
                a = a.replace(/(\d)(?=(\d{3})+$)/g, "$1" + o.delimiter);
            }
            return (
              n + a.toString() + (o.numeralDecimalScale > 0 ? l.toString() : "")
            );
          }
        }),
        (e.exports = r);
    },
    function(e, t) {
      "use strict";
      var r = function(e, t, r) {
        var i = this;
        (i.date = []),
          (i.blocks = []),
          (i.datePattern = e),
          (i.dateMin = t
            .split("-")
            .reverse()
            .map(function(e) {
              return parseInt(e, 10);
            })),
          2 === i.dateMin.length && i.dateMin.unshift(0),
          (i.dateMax = r
            .split("-")
            .reverse()
            .map(function(e) {
              return parseInt(e, 10);
            })),
          2 === i.dateMax.length && i.dateMax.unshift(0),
          i.initBlocks();
      };
      (r.prototype = {
        initBlocks: function() {
          var e = this;
          e.datePattern.forEach(function(t) {
            "Y" === t ? e.blocks.push(4) : e.blocks.push(2);
          });
        },
        getISOFormatDate: function() {
          var e = this,
            t = e.date;
          return t[2]
            ? t[2] + "-" + e.addLeadingZero(t[1]) + "-" + e.addLeadingZero(t[0])
            : "";
        },
        getBlocks: function() {
          return this.blocks;
        },
        getValidatedDate: function(e) {
          var t = this,
            r = "";
          return (
            (e = e.replace(/[^\d]/g, "")),
            t.blocks.forEach(function(i, n) {
              if (e.length > 0) {
                var a = e.slice(0, i),
                  o = a.slice(0, 1),
                  l = e.slice(i);
                switch (t.datePattern[n]) {
                  case "d":
                    "00" === a
                      ? (a = "01")
                      : parseInt(o, 10) > 3
                      ? (a = "0" + o)
                      : parseInt(a, 10) > 31 && (a = "31");
                    break;
                  case "m":
                    "00" === a
                      ? (a = "01")
                      : parseInt(o, 10) > 1
                      ? (a = "0" + o)
                      : parseInt(a, 10) > 12 && (a = "12");
                }
                (r += a), (e = l);
              }
            }),
            this.getFixedDateString(r)
          );
        },
        getFixedDateString: function(e) {
          var t,
            r,
            i,
            n = this,
            a = n.datePattern,
            o = [],
            l = 0,
            s = 0,
            c = 0,
            u = 0,
            d = 0,
            m = 0,
            p = !1;
          4 === e.length &&
            "y" !== a[0].toLowerCase() &&
            "y" !== a[1].toLowerCase() &&
            ((u = "d" === a[0] ? 0 : 2),
            (d = 2 - u),
            (t = parseInt(e.slice(u, u + 2), 10)),
            (r = parseInt(e.slice(d, d + 2), 10)),
            (o = this.getFixedDate(t, r, 0))),
            8 === e.length &&
              (a.forEach(function(e, t) {
                switch (e) {
                  case "d":
                    l = t;
                    break;
                  case "m":
                    s = t;
                    break;
                  default:
                    c = t;
                }
              }),
              (m = 2 * c),
              (u = l <= c ? 2 * l : 2 * l + 2),
              (d = s <= c ? 2 * s : 2 * s + 2),
              (t = parseInt(e.slice(u, u + 2), 10)),
              (r = parseInt(e.slice(d, d + 2), 10)),
              (i = parseInt(e.slice(m, m + 4), 10)),
              (p = 4 === e.slice(m, m + 4).length),
              (o = this.getFixedDate(t, r, i))),
            4 !== e.length ||
              ("y" !== a[0] && "y" !== a[1]) ||
              ((d = "m" === a[0] ? 0 : 2),
              (m = 2 - d),
              (r = parseInt(e.slice(d, d + 2), 10)),
              (i = parseInt(e.slice(m, m + 2), 10)),
              (p = 2 === e.slice(m, m + 2).length),
              (o = [0, r, i])),
            6 !== e.length ||
              ("Y" !== a[0] && "Y" !== a[1]) ||
              ((d = "m" === a[0] ? 0 : 4),
              (m = 2 - 0.5 * d),
              (r = parseInt(e.slice(d, d + 2), 10)),
              (i = parseInt(e.slice(m, m + 4), 10)),
              (p = 4 === e.slice(m, m + 4).length),
              (o = [0, r, i])),
            (o = n.getRangeFixedDate(o)),
            (n.date = o);
          var h =
            0 === o.length
              ? e
              : a.reduce(function(e, t) {
                  switch (t) {
                    case "d":
                      return e + (0 === o[0] ? "" : n.addLeadingZero(o[0]));
                    case "m":
                      return e + (0 === o[1] ? "" : n.addLeadingZero(o[1]));
                    case "y":
                      return e + (p ? n.addLeadingZeroForYear(o[2], !1) : "");
                    case "Y":
                      return e + (p ? n.addLeadingZeroForYear(o[2], !0) : "");
                  }
                }, "");
          return h;
        },
        getRangeFixedDate: function(e) {
          var t = this,
            r = t.datePattern,
            i = t.dateMin || [],
            n = t.dateMax || [];
          return !e.length || (i.length < 3 && n.length < 3)
            ? e
            : r.find(function(e) {
                return "y" === e.toLowerCase();
              }) && 0 === e[2]
            ? e
            : n.length &&
              (n[2] < e[2] ||
                (n[2] === e[2] &&
                  (n[1] < e[1] || (n[1] === e[1] && n[0] < e[0]))))
            ? n
            : i.length &&
              (i[2] > e[2] ||
                (i[2] === e[2] &&
                  (i[1] > e[1] || (i[1] === e[1] && i[0] > e[0]))))
            ? i
            : e;
        },
        getFixedDate: function(e, t, r) {
          return (
            (e = Math.min(e, 31)),
            (t = Math.min(t, 12)),
            (r = parseInt(r || 0, 10)),
            ((t < 7 && t % 2 === 0) || (t > 8 && t % 2 === 1)) &&
              (e = Math.min(e, 2 === t ? (this.isLeapYear(r) ? 29 : 28) : 30)),
            [e, t, r]
          );
        },
        isLeapYear: function(e) {
          return (e % 4 === 0 && e % 100 !== 0) || e % 400 === 0;
        },
        addLeadingZero: function(e) {
          return (e < 10 ? "0" : "") + e;
        },
        addLeadingZeroForYear: function(e, t) {
          return t
            ? (e < 10 ? "000" : e < 100 ? "00" : e < 1e3 ? "0" : "") + e
            : (e < 10 ? "0" : "") + e;
        }
      }),
        (e.exports = r);
    },
    function(e, t) {
      "use strict";
      var r = function(e, t) {
        var r = this;
        (r.time = []),
          (r.blocks = []),
          (r.timePattern = e),
          (r.timeFormat = t),
          r.initBlocks();
      };
      (r.prototype = {
        initBlocks: function() {
          var e = this;
          e.timePattern.forEach(function() {
            e.blocks.push(2);
          });
        },
        getISOFormatTime: function() {
          var e = this,
            t = e.time;
          return t[2]
            ? e.addLeadingZero(t[0]) +
                ":" +
                e.addLeadingZero(t[1]) +
                ":" +
                e.addLeadingZero(t[2])
            : "";
        },
        getBlocks: function() {
          return this.blocks;
        },
        getTimeFormatOptions: function() {
          var e = this;
          return "12" === String(e.timeFormat)
            ? {
                maxHourFirstDigit: 1,
                maxHours: 12,
                maxMinutesFirstDigit: 5,
                maxMinutes: 60
              }
            : {
                maxHourFirstDigit: 2,
                maxHours: 23,
                maxMinutesFirstDigit: 5,
                maxMinutes: 60
              };
        },
        getValidatedTime: function(e) {
          var t = this,
            r = "";
          e = e.replace(/[^\d]/g, "");
          var i = t.getTimeFormatOptions();
          return (
            t.blocks.forEach(function(n, a) {
              if (e.length > 0) {
                var o = e.slice(0, n),
                  l = o.slice(0, 1),
                  s = e.slice(n);
                switch (t.timePattern[a]) {
                  case "h":
                    parseInt(l, 10) > i.maxHourFirstDigit
                      ? (o = "0" + l)
                      : parseInt(o, 10) > i.maxHours && (o = i.maxHours + "");
                    break;
                  case "m":
                  case "s":
                    parseInt(l, 10) > i.maxMinutesFirstDigit
                      ? (o = "0" + l)
                      : parseInt(o, 10) > i.maxMinutes &&
                        (o = i.maxMinutes + "");
                }
                (r += o), (e = s);
              }
            }),
            this.getFixedTimeString(r)
          );
        },
        getFixedTimeString: function(e) {
          var t,
            r,
            i,
            n = this,
            a = n.timePattern,
            o = [],
            l = 0,
            s = 0,
            c = 0,
            u = 0,
            d = 0,
            m = 0;
          return (
            6 === e.length &&
              (a.forEach(function(e, t) {
                switch (e) {
                  case "s":
                    l = 2 * t;
                    break;
                  case "m":
                    s = 2 * t;
                    break;
                  case "h":
                    c = 2 * t;
                }
              }),
              (m = c),
              (d = s),
              (u = l),
              (t = parseInt(e.slice(u, u + 2), 10)),
              (r = parseInt(e.slice(d, d + 2), 10)),
              (i = parseInt(e.slice(m, m + 2), 10)),
              (o = this.getFixedTime(i, r, t))),
            4 === e.length &&
              n.timePattern.indexOf("s") < 0 &&
              (a.forEach(function(e, t) {
                switch (e) {
                  case "m":
                    s = 2 * t;
                    break;
                  case "h":
                    c = 2 * t;
                }
              }),
              (m = c),
              (d = s),
              (t = 0),
              (r = parseInt(e.slice(d, d + 2), 10)),
              (i = parseInt(e.slice(m, m + 2), 10)),
              (o = this.getFixedTime(i, r, t))),
            (n.time = o),
            0 === o.length
              ? e
              : a.reduce(function(e, t) {
                  switch (t) {
                    case "s":
                      return e + n.addLeadingZero(o[2]);
                    case "m":
                      return e + n.addLeadingZero(o[1]);
                    case "h":
                      return e + n.addLeadingZero(o[0]);
                  }
                }, "")
          );
        },
        getFixedTime: function(e, t, r) {
          return (
            (r = Math.min(parseInt(r || 0, 10), 60)),
            (t = Math.min(t, 60)),
            (e = Math.min(e, 60)),
            [e, t, r]
          );
        },
        addLeadingZero: function(e) {
          return (e < 10 ? "0" : "") + e;
        }
      }),
        (e.exports = r);
    },
    function(e, t) {
      "use strict";
      var r = function(e, t) {
        var r = this;
        (r.delimiter = t || "" === t ? t : " "),
          (r.delimiterRE = t ? new RegExp("\\" + t, "g") : ""),
          (r.formatter = e);
      };
      (r.prototype = {
        setFormatter: function(e) {
          this.formatter = e;
        },
        format: function(e) {
          var t = this;
          t.formatter.clear(),
            (e = e.replace(/[^\d+]/g, "")),
            (e = e
              .replace(/^\+/, "B")
              .replace(/\+/g, "")
              .replace("B", "+")),
            (e = e.replace(t.delimiterRE, ""));
          for (var r, i = "", n = !1, a = 0, o = e.length; a < o; a++)
            (r = t.formatter.inputDigit(e.charAt(a))),
              /[\s()-]/g.test(r) ? ((i = r), (n = !0)) : n || (i = r);
          return (
            (i = i.replace(/[()]/g, "")), (i = i.replace(/[\s-]/g, t.delimiter))
          );
        }
      }),
        (e.exports = r);
    },
    function(e, t) {
      "use strict";
      var r = {
        blocks: {
          uatp: [4, 5, 6],
          amex: [4, 6, 5],
          diners: [4, 6, 4],
          discover: [4, 4, 4, 4],
          mastercard: [4, 4, 4, 4],
          dankort: [4, 4, 4, 4],
          instapayment: [4, 4, 4, 4],
          jcb15: [4, 6, 5],
          jcb: [4, 4, 4, 4],
          maestro: [4, 4, 4, 4],
          visa: [4, 4, 4, 4],
          mir: [4, 4, 4, 4],
          unionPay: [4, 4, 4, 4],
          general: [4, 4, 4, 4]
        },
        re: {
          uatp: /^(?!1800)1\d{0,14}/,
          amex: /^3[47]\d{0,13}/,
          discover: /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,
          diners: /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,
          mastercard: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,
          dankort: /^(5019|4175|4571)\d{0,12}/,
          instapayment: /^63[7-9]\d{0,13}/,
          jcb15: /^(?:2131|1800)\d{0,11}/,
          jcb: /^(?:35\d{0,2})\d{0,12}/,
          maestro: /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,
          mir: /^220[0-4]\d{0,12}/,
          visa: /^4\d{0,15}/,
          unionPay: /^62\d{0,14}/
        },
        getStrictBlocks: function(e) {
          var t = e.reduce(function(e, t) {
            return e + t;
          }, 0);
          return e.concat(19 - t);
        },
        getInfo: function(e, t) {
          var i = r.blocks,
            n = r.re;
          t = !!t;
          for (var a in n)
            if (n[a].test(e)) {
              var o = i[a];
              return { type: a, blocks: t ? this.getStrictBlocks(o) : o };
            }
          return {
            type: "unknown",
            blocks: t ? this.getStrictBlocks(i.general) : i.general
          };
        }
      };
      e.exports = r;
    },
    function(e, t) {
      "use strict";
      var r = {
        noop: function() {},
        strip: function(e, t) {
          return e.replace(t, "");
        },
        getPostDelimiter: function(e, t, r) {
          if (0 === r.length) return e.slice(-t.length) === t ? t : "";
          var i = "";
          return (
            r.forEach(function(t) {
              e.slice(-t.length) === t && (i = t);
            }),
            i
          );
        },
        getDelimiterREByDelimiter: function(e) {
          return new RegExp(e.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "g");
        },
        getNextCursorPosition: function(e, t, r, i, n) {
          return t.length === e
            ? r.length
            : e + this.getPositionOffset(e, t, r, i, n);
        },
        getPositionOffset: function(e, t, r, i, n) {
          var a, o, l;
          return (
            (a = this.stripDelimiters(t.slice(0, e), i, n)),
            (o = this.stripDelimiters(r.slice(0, e), i, n)),
            (l = a.length - o.length),
            0 !== l ? l / Math.abs(l) : 0
          );
        },
        stripDelimiters: function(e, t, r) {
          var i = this;
          if (0 === r.length) {
            var n = t ? i.getDelimiterREByDelimiter(t) : "";
            return e.replace(n, "");
          }
          return (
            r.forEach(function(t) {
              t.split("").forEach(function(t) {
                e = e.replace(i.getDelimiterREByDelimiter(t), "");
              });
            }),
            e
          );
        },
        headStr: function(e, t) {
          return e.slice(0, t);
        },
        getMaxLength: function(e) {
          return e.reduce(function(e, t) {
            return e + t;
          }, 0);
        },
        getPrefixStrippedValue: function(e, t, r, i, n, a, o) {
          if (0 === r) return e;
          if (i.slice(0, r) !== t) return o && !i && e ? e : "";
          var l = this.stripDelimiters(i, n, a);
          return e.slice(0, r) !== t ? l.slice(r) : e.slice(r);
        },
        getFirstDiffIndex: function(e, t) {
          for (var r = 0; e.charAt(r) === t.charAt(r); )
            if ("" === e.charAt(r++)) return -1;
          return r;
        },
        getFormattedValue: function(e, t, r, i, n, a) {
          var o,
            l = "",
            s = n.length > 0;
          return 0 === r
            ? e
            : (t.forEach(function(t, c) {
                if (e.length > 0) {
                  var u = e.slice(0, t),
                    d = e.slice(t);
                  (o = s ? n[a ? c - 1 : c] || o : i),
                    a
                      ? (c > 0 && (l += o), (l += u))
                      : ((l += u), u.length === t && c < r - 1 && (l += o)),
                    (e = d);
                }
              }),
              l);
        },
        fixPrefixCursor: function(e, t, r, i) {
          if (e) {
            var n = e.value,
              a = r || i[0] || " ";
            if (e.setSelectionRange && t && !(t.length + a.length < n.length)) {
              var o = 2 * n.length;
              setTimeout(function() {
                e.setSelectionRange(o, o);
              }, 1);
            }
          }
        },
        checkFullSelection: function(e) {
          try {
            var t = window.getSelection() || document.getSelection() || {};
            return t.toString().length === e.length;
          } catch (r) {}
          return !1;
        },
        setSelection: function(e, t, r) {
          if (e === this.getActiveElement(r) && !(e && e.value.length <= t))
            if (e.createTextRange) {
              var i = e.createTextRange();
              i.move("character", t), i.select();
            } else
              try {
                e.setSelectionRange(t, t);
              } catch (n) {
                console.warn(
                  "The input element type does not support selection"
                );
              }
        },
        getActiveElement: function(e) {
          var t = e.activeElement;
          return t && t.shadowRoot ? this.getActiveElement(t.shadowRoot) : t;
        },
        isAndroid: function() {
          return navigator && /android/i.test(navigator.userAgent);
        },
        isAndroidBackspaceKeydown: function(e, t) {
          return !!(this.isAndroid() && e && t) && t === e.slice(0, -1);
        }
      };
      e.exports = r;
    },
    function(e, t) {
      (function(t) {
        "use strict";
        var r = {
          assign: function(e, r) {
            return (
              (e = e || {}),
              (r = r || {}),
              (e.creditCard = !!r.creditCard),
              (e.creditCardStrictMode = !!r.creditCardStrictMode),
              (e.creditCardType = ""),
              (e.onCreditCardTypeChanged =
                r.onCreditCardTypeChanged || function() {}),
              (e.phone = !!r.phone),
              (e.phoneRegionCode = r.phoneRegionCode || "AU"),
              (e.phoneFormatter = {}),
              (e.time = !!r.time),
              (e.timePattern = r.timePattern || ["h", "m", "s"]),
              (e.timeFormat = r.timeFormat || "24"),
              (e.timeFormatter = {}),
              (e.date = !!r.date),
              (e.datePattern = r.datePattern || ["d", "m", "Y"]),
              (e.dateMin = r.dateMin || ""),
              (e.dateMax = r.dateMax || ""),
              (e.dateFormatter = {}),
              (e.numeral = !!r.numeral),
              (e.numeralIntegerScale =
                r.numeralIntegerScale > 0 ? r.numeralIntegerScale : 0),
              (e.numeralDecimalScale =
                r.numeralDecimalScale >= 0 ? r.numeralDecimalScale : 2),
              (e.numeralDecimalMark = r.numeralDecimalMark || "."),
              (e.numeralThousandsGroupStyle =
                r.numeralThousandsGroupStyle || "thousand"),
              (e.numeralPositiveOnly = !!r.numeralPositiveOnly),
              (e.stripLeadingZeroes = r.stripLeadingZeroes !== !1),
              (e.signBeforePrefix = !!r.signBeforePrefix),
              (e.numericOnly = e.creditCard || e.date || !!r.numericOnly),
              (e.uppercase = !!r.uppercase),
              (e.lowercase = !!r.lowercase),
              (e.prefix = e.creditCard || e.date ? "" : r.prefix || ""),
              (e.noImmediatePrefix = !!r.noImmediatePrefix),
              (e.prefixLength = e.prefix.length),
              (e.rawValueTrimPrefix = !!r.rawValueTrimPrefix),
              (e.copyDelimiter = !!r.copyDelimiter),
              (e.initValue =
                void 0 !== r.initValue && null !== r.initValue
                  ? r.initValue.toString()
                  : ""),
              (e.delimiter =
                r.delimiter || "" === r.delimiter
                  ? r.delimiter
                  : r.date
                  ? "/"
                  : r.time
                  ? ":"
                  : r.numeral
                  ? ","
                  : (r.phone, " ")),
              (e.delimiterLength = e.delimiter.length),
              (e.delimiterLazyShow = !!r.delimiterLazyShow),
              (e.delimiters = r.delimiters || []),
              (e.blocks = r.blocks || []),
              (e.blocksLength = e.blocks.length),
              (e.root = "object" == typeof t && t ? t : window),
              (e.document = r.document || e.root.document),
              (e.maxLength = 0),
              (e.backspace = !1),
              (e.result = ""),
              (e.onValueChanged = r.onValueChanged || function() {}),
              e
            );
          }
        };
        e.exports = r;
      }.call(
        t,
        (function() {
          return this;
        })()
      ));
    }
  ]);
});

!function() {
  function l(l, n) {
    var u = l.split("."),
      t = Y;
    u[0] in t || !t.execScript || t.execScript("var " + u[0]);
    for (var e; u.length && (e = u.shift()); )
      u.length || void 0 === n ? (t = t[e] ? t[e] : (t[e] = {})) : (t[e] = n);
  }
  function n(l, n) {
    function u() {}
    (u.prototype = n.prototype),
      (l.M = n.prototype),
      (l.prototype = new u()),
      (l.prototype.constructor = l),
      (l.N = function(l, u, t) {
        for (
          var e = Array(arguments.length - 2), r = 2;
          r < arguments.length;
          r++
        )
          e[r - 2] = arguments[r];
        return n.prototype[u].apply(l, e);
      });
  }
  function u(l, n) {
    null != l && this.a.apply(this, arguments);
  }
  function t(l) {
    l.b = "";
  }
  function e(l, n) {
    l.sort(n || r);
  }
  function r(l, n) {
    return l > n ? 1 : l < n ? -1 : 0;
  }
  function i(l) {
    var n,
      u = [],
      t = 0;
    for (n in l) u[t++] = l[n];
    return u;
  }
  function a(l, n) {
    (this.b = l), (this.a = {});
    for (var u = 0; u < n.length; u++) {
      var t = n[u];
      this.a[t.b] = t;
    }
  }
  function d(l) {
    return (
      (l = i(l.a)),
      e(l, function(l, n) {
        return l.b - n.b;
      }),
      l
    );
  }
  function o(l, n) {
    switch (
      ((this.b = l),
      (this.g = !!n.v),
      (this.a = n.c),
      (this.i = n.type),
      (this.h = !1),
      this.a)
    ) {
      case O:
      case H:
      case q:
      case X:
      case k:
      case L:
      case J:
        this.h = !0;
    }
    this.f = n.defaultValue;
  }
  function s() {
    (this.a = {}), (this.f = this.j().a), (this.b = this.g = null);
  }
  function f(l, n) {
    for (var u = d(l.j()), t = 0; t < u.length; t++) {
      var e = u[t],
        r = e.b;
      if (null != n.a[r]) {
        l.b && delete l.b[e.b];
        var i = 11 == e.a || 10 == e.a;
        if (e.g)
          for (var e = p(n, r) || [], a = 0; a < e.length; a++) {
            var o = l,
              s = r,
              c = i ? e[a].clone() : e[a];
            o.a[s] || (o.a[s] = []), o.a[s].push(c), o.b && delete o.b[s];
          }
        else
          (e = p(n, r)),
            i ? ((i = p(l, r)) ? f(i, e) : m(l, r, e.clone())) : m(l, r, e);
      }
    }
  }
  function p(l, n) {
    var u = l.a[n];
    if (null == u) return null;
    if (l.g) {
      if (!(n in l.b)) {
        var t = l.g,
          e = l.f[n];
        if (null != u)
          if (e.g) {
            for (var r = [], i = 0; i < u.length; i++) r[i] = t.b(e, u[i]);
            u = r;
          } else u = t.b(e, u);
        return (l.b[n] = u);
      }
      return l.b[n];
    }
    return u;
  }
  function c(l, n, u) {
    var t = p(l, n);
    return l.f[n].g ? t[u || 0] : t;
  }
  function h(l, n) {
    var u;
    if (null != l.a[n]) u = c(l, n, void 0);
    else
      l: {
        if (((u = l.f[n]), void 0 === u.f)) {
          var t = u.i;
          if (t === Boolean) u.f = !1;
          else if (t === Number) u.f = 0;
          else {
            if (t !== String) {
              u = new t();
              break l;
            }
            u.f = u.h ? "0" : "";
          }
        }
        u = u.f;
      }
    return u;
  }
  function g(l, n) {
    return l.f[n].g
      ? null != l.a[n]
        ? l.a[n].length
        : 0
      : null != l.a[n]
      ? 1
      : 0;
  }
  function m(l, n, u) {
    (l.a[n] = u), l.b && (l.b[n] = u);
  }
  function b(l, n) {
    var u,
      t = [];
    for (u in n) 0 != u && t.push(new o(u, n[u]));
    return new a(l, t);
  } /*

 Protocol Buffer 2 Copyright 2008 Google Inc.
 All other code copyright its respective owners.
 Copyright (C) 2010 The Libphonenumber Authors

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
  function y() {
    s.call(this);
  }
  function v() {
    s.call(this);
  }
  function S() {
    s.call(this);
  }
  function _() {}
  function w() {}
  function A() {} /*

 Copyright (C) 2010 The Libphonenumber Authors.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
  function x() {
    this.a = {};
  }
  function B(l) {
    return 0 == l.length || rl.test(l);
  }
  function C(l, n) {
    if (null == n) return null;
    n = n.toUpperCase();
    var u = l.a[n];
    if (null == u) {
      if (((u = nl[n]), null == u)) return null;
      (u = new A().a(S.j(), u)), (l.a[n] = u);
    }
    return u;
  }
  function M(l) {
    return (l = ll[l]), null == l ? "ZZ" : l[0];
  }
  function N(l) {
    (this.H = RegExp(" ")),
      (this.C = ""),
      (this.m = new u()),
      (this.w = ""),
      (this.i = new u()),
      (this.u = new u()),
      (this.l = !0),
      (this.A = this.o = this.F = !1),
      (this.G = x.b()),
      (this.s = 0),
      (this.b = new u()),
      (this.B = !1),
      (this.h = ""),
      (this.a = new u()),
      (this.f = []),
      (this.D = l),
      (this.J = this.g = D(this, this.D));
  }
  function D(l, n) {
    var u;
    if (null != n && isNaN(n) && n.toUpperCase() in nl) {
      if (((u = C(l.G, n)), null == u))
        throw Error("Invalid region code: " + n);
      u = h(u, 10);
    } else u = 0;
    return (u = C(l.G, M(u))), null != u ? u : il;
  }
  function G(l) {
    for (var n = l.f.length, u = 0; u < n; ++u) {
      var e = l.f[u],
        r = h(e, 1);
      if (l.w == r) return !1;
      var i;
      i = l;
      var a = e,
        d = h(a, 1);
      if (-1 != d.indexOf("|")) i = !1;
      else {
        (d = d.replace(al, "\\d")), (d = d.replace(dl, "\\d")), t(i.m);
        var o;
        o = i;
        var a = h(a, 2),
          s = "999999999999999".match(d)[0];
        s.length < o.a.b.length
          ? (o = "")
          : ((o = s.replace(new RegExp(d, "g"), a)),
            (o = o.replace(RegExp("9", "g"), " "))),
          0 < o.length ? (i.m.a(o), (i = !0)) : (i = !1);
      }
      if (i) return (l.w = r), (l.B = sl.test(c(e, 4))), (l.s = 0), !0;
    }
    return (l.l = !1);
  }
  function j(l, n) {
    for (var u = [], t = n.length - 3, e = l.f.length, r = 0; r < e; ++r) {
      var i = l.f[r];
      0 == g(i, 3)
        ? u.push(l.f[r])
        : ((i = c(i, 3, Math.min(t, g(i, 3) - 1))),
          0 == n.search(i) && u.push(l.f[r]));
    }
    l.f = u;
  }
  function I(l, n) {
    l.i.a(n);
    var u = n;
    if (el.test(u) || (1 == l.i.b.length && tl.test(u))) {
      var e,
        u = n;
      "+" == u ? ((e = u), l.u.a(u)) : ((e = ul[u]), l.u.a(e), l.a.a(e)),
        (n = e);
    } else (l.l = !1), (l.F = !0);
    if (!l.l) {
      if (!l.F)
        if (F(l)) {
          if (U(l)) return V(l);
        } else if (
          (0 < l.h.length &&
            ((u = l.a.toString()),
            t(l.a),
            l.a.a(l.h),
            l.a.a(u),
            (u = l.b.toString()),
            (e = u.lastIndexOf(l.h)),
            t(l.b),
            l.b.a(u.substring(0, e))),
          l.h != P(l))
        )
          return l.b.a(" "), V(l);
      return l.i.toString();
    }
    switch (l.u.b.length) {
      case 0:
      case 1:
      case 2:
        return l.i.toString();
      case 3:
        if (!F(l)) return (l.h = P(l)), E(l);
        l.A = !0;
      default:
        return l.A
          ? (U(l) && (l.A = !1), l.b.toString() + l.a.toString())
          : 0 < l.f.length
          ? ((u = K(l, n)),
            (e = $(l)),
            0 < e.length
              ? e
              : (j(l, l.a.toString()),
                G(l) ? T(l) : l.l ? R(l, u) : l.i.toString()))
          : E(l);
    }
  }
  function V(l) {
    return (
      (l.l = !0), (l.A = !1), (l.f = []), (l.s = 0), t(l.m), (l.w = ""), E(l)
    );
  }
  function $(l) {
    for (var n = l.a.toString(), u = l.f.length, t = 0; t < u; ++t) {
      var e = l.f[t],
        r = h(e, 1);
      if (new RegExp("^(?:" + r + ")$").test(n))
        return (
          (l.B = sl.test(c(e, 4))),
          (n = n.replace(new RegExp(r, "g"), c(e, 2))),
          R(l, n)
        );
    }
    return "";
  }
  function R(l, n) {
    var u = l.b.b.length;
    return l.B && 0 < u && " " != l.b.toString().charAt(u - 1)
      ? l.b + " " + n
      : l.b + n;
  }
  function E(l) {
    var n = l.a.toString();
    if (3 <= n.length) {
      for (
        var u =
            l.o && 0 == l.h.length && 0 < g(l.g, 20)
              ? p(l.g, 20) || []
              : p(l.g, 19) || [],
          t = u.length,
          e = 0;
        e < t;
        ++e
      ) {
        var r = u[e];
        (0 < l.h.length && B(h(r, 4)) && !c(r, 6) && null == r.a[5]) ||
          ((0 != l.h.length || l.o || B(h(r, 4)) || c(r, 6)) &&
            ol.test(h(r, 2)) &&
            l.f.push(r));
      }
      return (
        j(l, n), (n = $(l)), 0 < n.length ? n : G(l) ? T(l) : l.i.toString()
      );
    }
    return R(l, n);
  }
  function T(l) {
    var n = l.a.toString(),
      u = n.length;
    if (0 < u) {
      for (var t = "", e = 0; e < u; e++) t = K(l, n.charAt(e));
      return l.l ? R(l, t) : l.i.toString();
    }
    return l.b.toString();
  }
  function P(l) {
    var n,
      u = l.a.toString(),
      e = 0;
    return (
      1 != c(l.g, 10)
        ? (n = !1)
        : ((n = l.a.toString()),
          (n = "1" == n.charAt(0) && "0" != n.charAt(1) && "1" != n.charAt(1))),
      n
        ? ((e = 1), l.b.a("1").a(" "), (l.o = !0))
        : null != l.g.a[15] &&
          ((n = new RegExp("^(?:" + c(l.g, 15) + ")")),
          (n = u.match(n)),
          null != n &&
            null != n[0] &&
            0 < n[0].length &&
            ((l.o = !0), (e = n[0].length), l.b.a(u.substring(0, e)))),
      t(l.a),
      l.a.a(u.substring(e)),
      u.substring(0, e)
    );
  }
  function F(l) {
    var n = l.u.toString(),
      u = new RegExp("^(?:\\+|" + c(l.g, 11) + ")"),
      u = n.match(u);
    return (
      null != u &&
      null != u[0] &&
      0 < u[0].length &&
      ((l.o = !0),
      (u = u[0].length),
      t(l.a),
      l.a.a(n.substring(u)),
      t(l.b),
      l.b.a(n.substring(0, u)),
      "+" != n.charAt(0) && l.b.a(" "),
      !0)
    );
  }
  function U(l) {
    if (0 == l.a.b.length) return !1;
    var n,
      e = new u();
    l: {
      if (((n = l.a.toString()), 0 != n.length && "0" != n.charAt(0)))
        for (var r, i = n.length, a = 1; 3 >= a && a <= i; ++a)
          if (((r = parseInt(n.substring(0, a), 10)), r in ll)) {
            e.a(n.substring(a)), (n = r);
            break l;
          }
      n = 0;
    }
    return (
      0 != n &&
      (t(l.a),
      l.a.a(e.toString()),
      (e = M(n)),
      "001" == e ? (l.g = C(l.G, "" + n)) : e != l.D && (l.g = D(l, e)),
      l.b.a("" + n).a(" "),
      (l.h = ""),
      !0)
    );
  }
  function K(l, n) {
    var u = l.m.toString();
    if (0 <= u.substring(l.s).search(l.H)) {
      var e = u.search(l.H),
        u = u.replace(l.H, n);
      return t(l.m), l.m.a(u), (l.s = e), u.substring(0, l.s + 1);
    }
    return 1 == l.f.length && (l.l = !1), (l.w = ""), l.i.toString();
  }
  var Y = this;
  (u.prototype.b = ""),
    (u.prototype.set = function(l) {
      this.b = "" + l;
    }),
    (u.prototype.a = function(l, n, u) {
      if (((this.b += String(l)), null != n))
        for (var t = 1; t < arguments.length; t++) this.b += arguments[t];
      return this;
    }),
    (u.prototype.toString = function() {
      return this.b;
    });
  var J = 1,
    L = 2,
    O = 3,
    H = 4,
    q = 6,
    X = 16,
    k = 18;
  (s.prototype.set = function(l, n) {
    m(this, l.b, n);
  }),
    (s.prototype.clone = function() {
      var l = new this.constructor();
      return l != this && ((l.a = {}), l.b && (l.b = {}), f(l, this)), l;
    }),
    n(y, s);
  var Z = null;
  n(v, s);
  var z = null;
  n(S, s);
  var Q = null;
  (y.prototype.j = function() {
    var l = Z;
    return (
      l ||
        (Z = l = b(y, {
          0: { name: "NumberFormat", I: "i18n.phonenumbers.NumberFormat" },
          1: { name: "pattern", required: !0, c: 9, type: String },
          2: { name: "format", required: !0, c: 9, type: String },
          3: { name: "leading_digits_pattern", v: !0, c: 9, type: String },
          4: { name: "national_prefix_formatting_rule", c: 9, type: String },
          6: {
            name: "national_prefix_optional_when_formatting",
            c: 8,
            defaultValue: !1,
            type: Boolean
          },
          5: {
            name: "domestic_carrier_code_formatting_rule",
            c: 9,
            type: String
          }
        })),
      l
    );
  }),
    (y.j = y.prototype.j),
    (v.prototype.j = function() {
      var l = z;
      return (
        l ||
          (z = l = b(v, {
            0: {
              name: "PhoneNumberDesc",
              I: "i18n.phonenumbers.PhoneNumberDesc"
            },
            2: { name: "national_number_pattern", c: 9, type: String },
            9: { name: "possible_length", v: !0, c: 5, type: Number },
            10: {
              name: "possible_length_local_only",
              v: !0,
              c: 5,
              type: Number
            },
            6: { name: "example_number", c: 9, type: String }
          })),
        l
      );
    }),
    (v.j = v.prototype.j),
    (S.prototype.j = function() {
      var l = Q;
      return (
        l ||
          (Q = l = b(S, {
            0: { name: "PhoneMetadata", I: "i18n.phonenumbers.PhoneMetadata" },
            1: { name: "general_desc", c: 11, type: v },
            2: { name: "fixed_line", c: 11, type: v },
            3: { name: "mobile", c: 11, type: v },
            4: { name: "toll_free", c: 11, type: v },
            5: { name: "premium_rate", c: 11, type: v },
            6: { name: "shared_cost", c: 11, type: v },
            7: { name: "personal_number", c: 11, type: v },
            8: { name: "voip", c: 11, type: v },
            21: { name: "pager", c: 11, type: v },
            25: { name: "uan", c: 11, type: v },
            27: { name: "emergency", c: 11, type: v },
            28: { name: "voicemail", c: 11, type: v },
            29: { name: "short_code", c: 11, type: v },
            30: { name: "standard_rate", c: 11, type: v },
            31: { name: "carrier_specific", c: 11, type: v },
            33: { name: "sms_services", c: 11, type: v },
            24: { name: "no_international_dialling", c: 11, type: v },
            9: { name: "id", required: !0, c: 9, type: String },
            10: { name: "country_code", c: 5, type: Number },
            11: { name: "international_prefix", c: 9, type: String },
            17: { name: "preferred_international_prefix", c: 9, type: String },
            12: { name: "national_prefix", c: 9, type: String },
            13: { name: "preferred_extn_prefix", c: 9, type: String },
            15: { name: "national_prefix_for_parsing", c: 9, type: String },
            16: { name: "national_prefix_transform_rule", c: 9, type: String },
            18: {
              name: "same_mobile_and_fixed_line_pattern",
              c: 8,
              defaultValue: !1,
              type: Boolean
            },
            19: { name: "number_format", v: !0, c: 11, type: y },
            20: { name: "intl_number_format", v: !0, c: 11, type: y },
            22: {
              name: "main_country_for_code",
              c: 8,
              defaultValue: !1,
              type: Boolean
            },
            23: { name: "leading_digits", c: 9, type: String },
            26: {
              name: "leading_zero_possible",
              c: 8,
              defaultValue: !1,
              type: Boolean
            }
          })),
        l
      );
    }),
    (S.j = S.prototype.j),
    (_.prototype.a = function(l) {
      throw (new l.b(), Error("Unimplemented"));
    }),
    (_.prototype.b = function(l, n) {
      if (11 == l.a || 10 == l.a)
        return n instanceof s ? n : this.a(l.i.prototype.j(), n);
      if (14 == l.a) {
        if ("string" == typeof n && W.test(n)) {
          var u = Number(n);
          if (0 < u) return u;
        }
        return n;
      }
      if (!l.h) return n;
      if (((u = l.i), u === String)) {
        if ("number" == typeof n) return String(n);
      } else if (
        u === Number &&
        "string" == typeof n &&
        ("Infinity" === n || "-Infinity" === n || "NaN" === n || W.test(n))
      )
        return Number(n);
      return n;
    });
  var W = /^-?[0-9]+$/;
  n(w, _),
    (w.prototype.a = function(l, n) {
      var u = new l.b();
      return (u.g = this), (u.a = n), (u.b = {}), u;
    }),
    n(A, w),
    (A.prototype.b = function(l, n) {
      return 8 == l.a ? !!n : _.prototype.b.apply(this, arguments);
    }),
    (A.prototype.a = function(l, n) {
      return A.M.a.call(this, l, n);
    }); /*

 Copyright (C) 2010 The Libphonenumber Authors

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
  var ll = {
      1: "US AG AI AS BB BM BS CA DM DO GD GU JM KN KY LC MP MS PR SX TC TT VC VG VI".split(
        " "
      )
    },
    nl = {
      AG: [
        null,
        [
          null,
          null,
          "(?:268|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "268(?:4(?:6[0-38]|84)|56[0-2])\\d{4}",
          null,
          null,
          null,
          "2684601234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "268(?:464|7(?:1[3-9]|2\\d|3[246]|64|[78][0-689]))\\d{4}",
          null,
          null,
          null,
          "2684641234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [
          null,
          null,
          "26848[01]\\d{4}",
          null,
          null,
          null,
          "2684801234",
          null,
          null,
          null,
          [7]
        ],
        "AG",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [
          null,
          null,
          "26840[69]\\d{4}",
          null,
          null,
          null,
          "2684061234",
          null,
          null,
          null,
          [7]
        ],
        null,
        "268",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      AI: [
        null,
        [
          null,
          null,
          "(?:264|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "2644(?:6[12]|9[78])\\d{4}",
          null,
          null,
          null,
          "2644612345",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "264(?:235|476|5(?:3[6-9]|8[1-4])|7(?:29|72))\\d{4}",
          null,
          null,
          null,
          "2642351234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "AI",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "264",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      AS: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|684|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "6846(?:22|33|44|55|77|88|9[19])\\d{4}",
          null,
          null,
          null,
          "6846221234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "684(?:2(?:5[2468]|72)|7(?:3[13]|70))\\d{4}",
          null,
          null,
          null,
          "6847331234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "AS",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "684",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      BB: [
        null,
        [
          null,
          null,
          "(?:246|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "246(?:2(?:2[78]|7[0-4])|4(?:1[024-6]|2\\d|3[2-9])|5(?:20|[34]\\d|54|7[1-3])|6(?:2\\d|38)|7[35]7|9(?:1[89]|63))\\d{4}",
          null,
          null,
          null,
          "2464123456",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "246(?:2(?:[356]\\d|4[0-57-9]|8[0-79])|45\\d|69[5-7]|8(?:[2-5]\\d|83))\\d{4}",
          null,
          null,
          null,
          "2462501234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [
          null,
          null,
          "(?:246976|900[2-9]\\d\\d)\\d{4}",
          null,
          null,
          null,
          "9002123456",
          null,
          null,
          null,
          [7]
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [
          null,
          null,
          "24631\\d{5}",
          null,
          null,
          null,
          "2463101234",
          null,
          null,
          null,
          [7]
        ],
        "BB",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "246",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "246(?:292|367|4(?:1[7-9]|3[01]|44|67)|7(?:36|53))\\d{4}",
          null,
          null,
          null,
          "2464301234",
          null,
          null,
          null,
          [7]
        ],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      BM: [
        null,
        [
          null,
          null,
          "(?:441|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "441(?:2(?:02|23|[3479]\\d|61)|[46]\\d\\d|5(?:4\\d|60|89)|824)\\d{4}",
          null,
          null,
          null,
          "4412345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "441(?:[37]\\d|5[0-39])\\d{5}",
          null,
          null,
          null,
          "4413701234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "BM",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "441",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      BS: [
        null,
        [
          null,
          null,
          "(?:242|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "242(?:3(?:02|[236][1-9]|4[0-24-9]|5[0-68]|7[347]|8[0-4]|9[2-467])|461|502|6(?:0[1-4]|12|2[013]|[45]0|7[67]|8[78]|9[89])|7(?:02|88))\\d{4}",
          null,
          null,
          null,
          "2423456789",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "242(?:3(?:5[79]|7[56]|95)|4(?:[23][1-9]|4[1-35-9]|5[1-8]|6[2-8]|7\\d|81)|5(?:2[45]|3[35]|44|5[1-46-9]|65|77)|6[34]6|7(?:27|38)|8(?:0[1-9]|1[02-9]|2\\d|[89]9))\\d{4}",
          null,
          null,
          null,
          "2423591234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "(?:242300|8(?:00|33|44|55|66|77|88)[2-9]\\d\\d)\\d{4}",
          null,
          null,
          null,
          "8002123456",
          null,
          null,
          null,
          [7]
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "BS",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "242",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, "242225[0-46-9]\\d{3}", null, null, null, "2422250123"],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      CA: [
        null,
        [
          null,
          null,
          "(?:[2-8]\\d|90)\\d{8}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "(?:2(?:04|[23]6|[48]9|50)|3(?:06|43|65)|4(?:03|1[68]|3[178]|50)|5(?:06|1[49]|48|79|8[17])|6(?:04|13|39|47)|7(?:0[59]|78|8[02])|8(?:[06]7|19|25|73)|90[25])[2-9]\\d{6}",
          null,
          null,
          null,
          "5062345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "(?:2(?:04|[23]6|[48]9|50)|3(?:06|43|65)|4(?:03|1[68]|3[178]|50)|5(?:06|1[49]|48|79|8[17])|6(?:04|13|39|47)|7(?:0[59]|78|8[02])|8(?:[06]7|19|25|73)|90[25])[2-9]\\d{6}",
          null,
          null,
          null,
          "5062345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "(?:5(?:00|2[12]|33|44|66|77|88)|622)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, "600[2-9]\\d{6}", null, null, null, "6002012345"],
        "CA",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        1,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      DM: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|767|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "767(?:2(?:55|66)|4(?:2[01]|4[0-25-9])|50[0-4]|70[1-3])\\d{4}",
          null,
          null,
          null,
          "7674201234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "767(?:2(?:[2-4689]5|7[5-7])|31[5-7]|61[1-7])\\d{4}",
          null,
          null,
          null,
          "7672251234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "DM",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "767",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      DO: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "8(?:[04]9[2-9]\\d\\d|29(?:2(?:[0-59]\\d|6[04-9]|7[0-27]|8[0237-9])|3(?:[0-35-9]\\d|4[7-9])|[45]\\d\\d|6(?:[0-27-9]\\d|[3-5][1-9]|6[0135-8])|7(?:0[013-9]|[1-37]\\d|4[1-35689]|5[1-4689]|6[1-57-9]|8[1-79]|9[1-8])|8(?:0[146-9]|1[0-48]|[248]\\d|3[1-79]|5[01589]|6[013-68]|7[124-8]|9[0-8])|9(?:[0-24]\\d|3[02-46-9]|5[0-79]|60|7[0169]|8[57-9]|9[02-9])))\\d{4}",
          null,
          null,
          null,
          "8092345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8[024]9[2-9]\\d{6}",
          null,
          null,
          null,
          "8092345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "DO",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "8[024]9",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      GD: [
        null,
        [
          null,
          null,
          "(?:473|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "473(?:2(?:3[0-2]|69)|3(?:2[89]|86)|4(?:[06]8|3[5-9]|4[0-49]|5[5-79]|73|90)|63[68]|7(?:58|84)|800|938)\\d{4}",
          null,
          null,
          null,
          "4732691234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "473(?:4(?:0[2-79]|1[04-9]|2[0-5]|58)|5(?:2[01]|3[3-8])|901)\\d{4}",
          null,
          null,
          null,
          "4734031234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "GD",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "473",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      GU: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|671|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "671(?:3(?:00|3[39]|4[349]|55|6[26])|4(?:00|56|7[1-9]|8[0236-9])|5(?:55|6[2-5]|88)|6(?:3[2-578]|4[24-9]|5[34]|78|8[235-9])|7(?:[0479]7|2[0167]|3[45]|8[7-9])|8(?:[2-57-9]8|6[48])|9(?:2[29]|6[79]|7[1279]|8[7-9]|9[78]))\\d{4}",
          null,
          null,
          null,
          "6713001234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "671(?:3(?:00|3[39]|4[349]|55|6[26])|4(?:00|56|7[1-9]|8[0236-9])|5(?:55|6[2-5]|88)|6(?:3[2-578]|4[24-9]|5[34]|78|8[235-9])|7(?:[0479]7|2[0167]|3[45]|8[7-9])|8(?:[2-57-9]8|6[48])|9(?:2[29]|6[79]|7[1279]|8[7-9]|9[78]))\\d{4}",
          null,
          null,
          null,
          "6713001234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "GU",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        1,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "671",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      JM: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|658|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "(?:658[2-9]\\d\\d|876(?:5(?:0[12]|1[0-468]|2[35]|63)|6(?:0[1-3579]|1[0237-9]|[23]\\d|40|5[06]|6[2-589]|7[05]|8[04]|9[4-9])|7(?:0[2-689]|[1-6]\\d|8[056]|9[45])|9(?:0[1-8]|1[02378]|[2-8]\\d|9[2-468])))\\d{4}",
          null,
          null,
          null,
          "8765230123",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "876(?:(?:2[14-9]|[348]\\d)\\d|5(?:0[3-9]|[2-57-9]\\d|6[0-24-9])|7(?:0[07]|7\\d|8[1-47-9]|9[0-36-9])|9(?:[01]9|9[0579]))\\d{4}",
          null,
          null,
          null,
          "8762101234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "JM",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "658|876",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      KN: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "869(?:2(?:29|36)|302|4(?:6[015-9]|70))\\d{4}",
          null,
          null,
          null,
          "8692361234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "869(?:5(?:5[6-8]|6[5-7])|66\\d|76[02-7])\\d{4}",
          null,
          null,
          null,
          "8697652917",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "KN",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "869",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      KY: [
        null,
        [
          null,
          null,
          "(?:345|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "345(?:2(?:22|44)|444|6(?:23|38|40)|7(?:4[35-79]|6[6-9]|77)|8(?:00|1[45]|25|[48]8)|9(?:14|4[035-9]))\\d{4}",
          null,
          null,
          null,
          "3452221234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "345(?:32[1-9]|5(?:1[67]|2[5-79]|4[6-9]|50|76)|649|9(?:1[67]|2[2-9]|3[689]))\\d{4}",
          null,
          null,
          null,
          "3453231234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [
          null,
          null,
          "(?:345976|900[2-9]\\d\\d)\\d{4}",
          null,
          null,
          null,
          "9002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "KY",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, "345849\\d{4}", null, null, null, "3458491234"],
        null,
        "345",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      LC: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|758|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "758(?:4(?:30|5\\d|6[2-9]|8[0-2])|57[0-2]|638)\\d{4}",
          null,
          null,
          null,
          "7584305678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "758(?:28[4-7]|384|4(?:6[01]|8[4-9])|5(?:1[89]|20|84)|7(?:1[2-9]|2\\d|3[01]))\\d{4}",
          null,
          null,
          null,
          "7582845678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "LC",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "758",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      MP: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|(?:67|90)0)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "670(?:2(?:3[3-7]|56|8[5-8])|32[1-38]|4(?:33|8[348])|5(?:32|55|88)|6(?:64|70|82)|78[3589]|8[3-9]8|989)\\d{4}",
          null,
          null,
          null,
          "6702345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "670(?:2(?:3[3-7]|56|8[5-8])|32[1-38]|4(?:33|8[348])|5(?:32|55|88)|6(?:64|70|82)|78[3589]|8[3-9]8|989)\\d{4}",
          null,
          null,
          null,
          "6702345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "MP",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        1,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "670",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      MS: [
        null,
        [
          null,
          null,
          "(?:(?:[58]\\d\\d|900)\\d\\d|66449)\\d{5}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "664491\\d{4}",
          null,
          null,
          null,
          "6644912345",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "66449[2-6]\\d{4}",
          null,
          null,
          null,
          "6644923456",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "MS",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "664",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      PR: [
        null,
        [
          null,
          null,
          "(?:[589]\\d\\d|787)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "(?:787|939)[2-9]\\d{6}",
          null,
          null,
          null,
          "7872345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "(?:787|939)[2-9]\\d{6}",
          null,
          null,
          null,
          "7872345678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002345678"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "PR",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        1,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "787|939",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      SX: [
        null,
        [
          null,
          null,
          "(?:(?:[58]\\d\\d|900)\\d|7215)\\d{6}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "7215(?:4[2-8]|8[239]|9[056])\\d{4}",
          null,
          null,
          null,
          "7215425678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "7215(?:1[02]|2\\d|5[034679]|8[014-8])\\d{4}",
          null,
          null,
          null,
          "7215205678",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002123456"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002123456"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "SX",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "721",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      TC: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|649|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "649(?:712|9(?:4\\d|50))\\d{4}",
          null,
          null,
          null,
          "6497121234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "649(?:2(?:3[129]|4[1-7])|3(?:3[1-389]|4[1-8])|4[34][1-3])\\d{4}",
          null,
          null,
          null,
          "6492311234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002345678"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [
          null,
          null,
          "64971[01]\\d{4}",
          null,
          null,
          null,
          "6497101234",
          null,
          null,
          null,
          [7]
        ],
        "TC",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "649",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      TT: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "868(?:2(?:01|[23]\\d)|6(?:0[7-9]|1[02-8]|2[1-9]|[3-69]\\d|7[0-79])|82[124])\\d{4}",
          null,
          null,
          null,
          "8682211234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "868(?:2(?:6[6-9]|[7-9]\\d)|[37](?:0[1-9]|1[02-9]|[2-9]\\d)|4[6-9]\\d|6(?:20|78|8\\d))\\d{4}",
          null,
          null,
          null,
          "8682911234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002345678"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "TT",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "868",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [
          null,
          null,
          "868619\\d{4}",
          null,
          null,
          null,
          "8686191234",
          null,
          null,
          null,
          [7]
        ]
      ],
      US: [
        null,
        [
          null,
          null,
          "[2-9]\\d{9}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "(?:2(?:0[1-35-9]|1[02-9]|2[03-589]|3[149]|4[08]|5[1-46]|6[0279]|7[0269]|8[13])|3(?:0[1-57-9]|1[02-9]|2[0135]|3[0-24679]|4[67]|5[12]|6[014]|8[056])|4(?:0[124-9]|1[02-579]|2[3-5]|3[0245]|4[0235]|58|6[39]|7[0589]|8[04])|5(?:0[1-57-9]|1[0235-8]|20|3[0149]|4[01]|5[19]|6[1-47]|7[013-5]|8[056])|6(?:0[1-35-9]|1[024-9]|2[03689]|[34][016]|5[017]|6[0-279]|78|8[0-2])|7(?:0[1-46-8]|1[2-9]|2[04-7]|3[1247]|4[037]|5[47]|6[02359]|7[02-59]|8[156])|8(?:0[1-68]|1[02-8]|2[08]|3[0-28]|4[3578]|5[046-9]|6[02-5]|7[028])|9(?:0[1346-9]|1[02-9]|2[0589]|3[0146-8]|4[0179]|5[12469]|7[0-389]|8[04-69]))[2-9]\\d{6}",
          null,
          null,
          null,
          "2015550123",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "(?:2(?:0[1-35-9]|1[02-9]|2[03-589]|3[149]|4[08]|5[1-46]|6[0279]|7[0269]|8[13])|3(?:0[1-57-9]|1[02-9]|2[0135]|3[0-24679]|4[67]|5[12]|6[014]|8[056])|4(?:0[124-9]|1[02-579]|2[3-5]|3[0245]|4[0235]|58|6[39]|7[0589]|8[04])|5(?:0[1-57-9]|1[0235-8]|20|3[0149]|4[01]|5[19]|6[1-47]|7[013-5]|8[056])|6(?:0[1-35-9]|1[024-9]|2[03689]|[34][016]|5[017]|6[0-279]|78|8[0-2])|7(?:0[1-46-8]|1[2-9]|2[04-7]|3[1247]|4[037]|5[47]|6[02359]|7[02-59]|8[156])|8(?:0[1-68]|1[02-8]|2[08]|3[0-28]|4[3578]|5[046-9]|6[02-5]|7[028])|9(?:0[1346-9]|1[02-9]|2[0589]|3[0146-8]|4[0179]|5[12469]|7[0-389]|8[04-69]))[2-9]\\d{6}",
          null,
          null,
          null,
          "2015550123",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002345678"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "US",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        1,
        [
          [null, "(\\d{3})(\\d{4})", "$1-$2", ["[2-9]"]],
          [
            null,
            "(\\d{3})(\\d{3})(\\d{4})",
            "($1) $2-$3",
            ["[2-9]"],
            null,
            null,
            1
          ]
        ],
        [[null, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2-$3", ["[2-9]"]]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        1,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, "710[2-9]\\d{6}", null, null, null, "7102123456"],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      VC: [
        null,
        [
          null,
          null,
          "(?:[58]\\d\\d|784|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "784(?:266|3(?:6[6-9]|7\\d|8[0-24-6])|4(?:38|5[0-36-8]|8[0-8])|5(?:55|7[0-2]|93)|638|784)\\d{4}",
          null,
          null,
          null,
          "7842661234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "784(?:4(?:3[0-5]|5[45]|89|9[0-8])|5(?:2[6-9]|3[0-4]))\\d{4}",
          null,
          null,
          null,
          "7844301234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002345678"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "VC",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "784",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      VG: [
        null,
        [
          null,
          null,
          "(?:284|[58]\\d\\d|900)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "284(?:(?:229|774|8(?:52|6[459]))\\d|4(?:22\\d|9(?:[45]\\d|6[0-5])))\\d{3}",
          null,
          null,
          null,
          "2842291234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "284(?:(?:3(?:0[0-3]|4[0-7]|68|9[34])|54[0-57])\\d|4(?:(?:4[0-6]|68)\\d|9(?:6[6-9]|9\\d)))\\d{3}",
          null,
          null,
          null,
          "2843001234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002345678"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "VG",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        null,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "284",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ],
      VI: [
        null,
        [
          null,
          null,
          "(?:(?:34|90)0|[58]\\d\\d)\\d{7}",
          null,
          null,
          null,
          null,
          null,
          null,
          [10],
          [7]
        ],
        [
          null,
          null,
          "340(?:2(?:01|2[06-8]|44|77)|3(?:32|44)|4(?:22|7[34])|5(?:1[34]|55)|6(?:26|4[23]|77|9[023])|7(?:1[2-57-9]|27|7\\d)|884|998)\\d{4}",
          null,
          null,
          null,
          "3406421234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "340(?:2(?:01|2[06-8]|44|77)|3(?:32|44)|4(?:22|7[34])|5(?:1[34]|55)|6(?:26|4[23]|77|9[023])|7(?:1[2-57-9]|27|7\\d)|884|998)\\d{4}",
          null,
          null,
          null,
          "3406421234",
          null,
          null,
          null,
          [7]
        ],
        [
          null,
          null,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "8002345678"
        ],
        [null, null, "900[2-9]\\d{6}", null, null, null, "9002345678"],
        [null, null, null, null, null, null, null, null, null, [-1]],
        [
          null,
          null,
          "5(?:00|2[12]|33|44|66|77|88)[2-9]\\d{6}",
          null,
          null,
          null,
          "5002345678"
        ],
        [null, null, null, null, null, null, null, null, null, [-1]],
        "VI",
        1,
        "011",
        "1",
        null,
        null,
        "1",
        null,
        null,
        1,
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        "340",
        [null, null, null, null, null, null, null, null, null, [-1]],
        [null, null, null, null, null, null, null, null, null, [-1]],
        null,
        null,
        [null, null, null, null, null, null, null, null, null, [-1]]
      ]
    };
  x.b = function() {
    return x.a ? x.a : (x.a = new x());
  };
  var ul = {
      0: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      "０": "0",
      "１": "1",
      "２": "2",
      "３": "3",
      "４": "4",
      "５": "5",
      "６": "6",
      "７": "7",
      "８": "8",
      "９": "9",
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
      "۰": "0",
      "۱": "1",
      "۲": "2",
      "۳": "3",
      "۴": "4",
      "۵": "5",
      "۶": "6",
      "۷": "7",
      "۸": "8",
      "۹": "9"
    },
    tl = RegExp("[+＋]+"),
    el = RegExp("([0-9０-９٠-٩۰-۹])"),
    rl = /^\(?\$1\)?$/,
    il = new S();
  m(il, 11, "NA");
  var al = /\[([^\[\]])*\]/g,
    dl = /\d(?=[^,}][^,}])/g,
    ol = RegExp(
      "^[-x‐-―−ー－-／  ­​⁠　()（）［］.\\[\\]/~⁓∼～]*(\\$\\d[-x‐-―−ー－-／  ­​⁠　()（）［］.\\[\\]/~⁓∼～]*)+$"
    ),
    sl = /[- ]/;
  (N.prototype.K = function() {
    (this.C = ""),
      t(this.i),
      t(this.u),
      t(this.m),
      (this.s = 0),
      (this.w = ""),
      t(this.b),
      (this.h = ""),
      t(this.a),
      (this.l = !0),
      (this.A = this.o = this.F = !1),
      (this.f = []),
      (this.B = !1),
      this.g != this.J && (this.g = D(this, this.D));
  }),
    (N.prototype.L = function(l) {
      return (this.C = I(this, l));
    }),
    l("Cleave.AsYouTypeFormatter", N),
    l("Cleave.AsYouTypeFormatter.prototype.inputDigit", N.prototype.L),
    l("Cleave.AsYouTypeFormatter.prototype.clear", N.prototype.K);
}.call("object" == typeof global && global ? global : window);

document.addEventListener("DOMContentLoaded", cleaver);

function cleaver() {
  new Cleave("#number-field", {
    phone: true,
    phoneRegionCode: "US"
  });
}
