document.addEventListener("DOMContentLoaded", handleGallery);

function handleGallery() {
  let bathroomButtonToActivate = document.querySelector(
    ".button-container #bathroom-button"
  );
  let bathroomGallery = document.querySelector("#bathroom-container");

  let bathRoomButtons = document
    .querySelectorAll("#bathroom-button")
    .forEach(button =>
      button.addEventListener("click", () => {
        showGallery(bathroomButtonToActivate, bathroomGallery);
      })
    );

  //////////////// Kitchens

  let kitchenButtonToActivate = document.querySelector(
    ".button-container #kitchen-button"
  );
  let kitchenGallery = document.querySelector("#kitchen-container");
  let kitchenRoomButtons = document
    .querySelectorAll("#kitchen-button")
    .forEach(button =>
      button.addEventListener("click", () => {
        showGallery(kitchenButtonToActivate, kitchenGallery);
      })
    );
  ///////////////////// Other

  let otherButtonToActivate = document.querySelector(
    ".button-container #other-button"
  );
  let otherGallery = document.querySelector("#other-container");
  let otherRoomButtons = document
    .querySelectorAll("#other-button")
    .forEach(button =>
      button.addEventListener("click", () => {
        showGallery(otherButtonToActivate, otherGallery);
      })
    );
  /////////////////////////
  /////////////////////////
  /////////////////////////
  /////////////////////////
  /////////////////////////
  /////////////////////////
  ///// Other Actions
  let exitButton = document.querySelector("#exit-icon");
  exitButton.addEventListener("click", function() {
    document.querySelector(".gallery-container").classList.add("hidden");
  });
}

function showGallery(button, gallery) {
  document.querySelector(".gallery-container").classList.remove("hidden");
  document
    .querySelectorAll(".button-container button")
    .forEach(button => button.classList.remove("active"));
  button.classList.add("active");
  document
    .querySelectorAll(".gallery-images-container")
    .forEach(gallery => gallery.classList.add("hidden"));
  gallery.classList.remove("hidden");
}
